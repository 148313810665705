const strings: { [k: string]: string } = {
  Or: `O`,
  Search: `Buscar`,
  Authors: `Autores`,
  Books: `Libros`,
  Compilations: `Compilaciones`,
  About: `Acerca de`,
  Help: `Ayuda`,
  Recommended: `Recomendado`,
  Recommendations: `Recomendaciones`,
  Cart: `Carrito`,
  Download: `Descargar`,
  Audiobooks: `Audiolibros`,
  Audiobook: `Audiolibro`,
  Listen: `Escuchar`,
  Paperback: `Libro Impreso`,
  by: `por`,
  Play: `Reproducir`,
  Pause: `Pausar`,
  Quakers: `Cuáqueros`,
  'Back 15 seconds': `Retroceder 15 segundos`,
  'Forward 15 seconds': `Avanzar 15 segundos`,
  'Play part %s': `Reproducir parte %s`,
  'Religious Society of Friends': `La Sociedad de Amigos (Cuáqueros)`,
  'Audio Book': `Audiolibro`,
  'All Audio Books': `Todos los Audiolibros`,
  'Audio Books': `Audiolibros`,
  'Recently Added Books': `Libros Añadidos Recientemente`,
  'Not Found': `Página No Encontrada`,
  'Recently Added Authors': `Autores Añadidos Recientemente`,
  'Learn More': `Más Información`,
  'View Compilations': `Ver Compilaciones`,
  'Getting Started': `Cómo Empezar`,
  'Explore Books': `Explorar los Libros`,
  'All Friends': `Todos los Amigos`,
  'About the Quakers': `Acerca de los Cuáqueros`,
  'About this Site': `Acerca de este sitio`,
  'Audio Help': `Ayuda para Audio`,
  'E-Book Help': `Ayuda para Libro Electrónico`,
  'Contact Us': `Contáctanos`,
  'The Friends Library': `La Biblioteca de los Amigos`,
  'Friends Library Publishing': `La Biblioteca de los Amigos`,
  'Friends Library': `Biblioteca de los Amigos`,
  'Friends Library App': `Aplicación de Bibliotecas de Los Amigos`,
  'View %s More': `Ver %s más`,
  'View More': `Ver más`,
  'Your search returned no results': `Tu búsqueda no obtuvo resultados`,

  // all friends filters
  Sort: `Ordenar por`,
  'First Name': `Nombre`,
  'Last Name': `Apellido`,
  'Birth Date': `Fecha de Nacimiento`,
  'Death Date': `Fecha de Muerte`,

  // tags, tag-like
  'Spiritual Life': `Vida Espiritual`,
  'spiritual life': `vida espiritual`,
  'spiritual-life': `vida-espiritual`,
  doctrinal: `doctrinal`,
  Doctrine: `Doctrinal`,
  history: `historia`,
  History: `Historia`,
  Biographical: `Biográfico`,
  biographical: `biográfico`,
  Tags: `Etiquetas`,
  journal: `diario`,
  letters: `cartas`,
  exhortation: `exhortación`,
  treatise: `tratado`,
  allegory: `alegoría`,
  devotional: `devocional`,

  // searching, filters
  Filters: `Filtros`,
  'All Books': `Todos los Libros`,
  'Select a filter or search to get started!': `¡Selecciona un filtro o realiza una búsqueda para empezar!`,

  // Home Page
  'Our Books': `Nuestros Libros`,
  Paperbacks: `Libros Impresos`,
  'E-Books': `Libros Electrónicos`,
  'Featured Books': `Libros Destacados`,
  'Find out more': `Más Información`,

  // search
  Pages: `Páginas`,
  Friends: `Amigos`,
  Page: `Página`,
  Residences: `Residencias`,
  'No results for query': `No hemos encontrado resultados para`,

  // Friend Page
  'All Compilations (%s)': `Todas las Compilaciones (%s)`,
  'Books by %s': `Libros de %s`,
  'View Book': `Ver Libro`,
  'Where did %s live?': `¿Dónde vivió %s?`,
  'Related Books': `Libros Relacionados`,

  // Document page
  'Download Audiobook': `Descargar Audiolibro`,
  'Download as': `Descargar como`,
  'Download mp3 Files as Zip': `Descargar archivos Mp3 como Zip`,
  'Download mp3 File': `Descargar archivo Mp3`,
  'Higher quality, larger file size': `Mayor Calidad, mayor tamaño del archivo`,
  'Lower quality, faster download': `Menor calidad, pero se descarga más rápido`,
  'Need Help?': `¿Necesitas Ayuda?`,
  'Listen online': `Escuchar en línea`,
  'Other Compilations': `Otras Compilaciones`,
  'Other Books by this Author': `Otros libros de este Autor`,
  '1 chapter': `1 capitulo`,
  '%s chapters': `%s capítulos`,
  '1 page': `1 pagina`,
  '%s pages': `%s páginas`,

  // urls
  '/getting-started': `/comenzar`,
  '/explore': `/explorar`,
  '/audiobooks': `/audiolibros`,
  '/compilations': `/compilaciones`,
  '/friends': `/amigos`,
  '/quakers': `/cuaqueros`,
  '/about': `/acerca-de-este-sitio`,
  '/audio-help': `/audio-ayuda`,
  '/ebook-help': `/ebook-ayuda`,
  '/contact': `/contactanos`,
  '/spanish-translations': `/nuestras-traducciones`,
  '/app': `/app`,
  '/app-privacy': `/app-privacidad`,
  '/plain-text-format': `/descargar-texto-sin-formato`,

  // contact
  Submit: `Enviar`,
  Submitting: `Enviando`,
  Contact: `Contacto`,
  Name: `Nombre`,
  Email: `Correo Electrónico`,
  Message: `Mensaje`,

  // download / add to cart wizard
  'Choose Book Type': `Escoger el tipo de Libro`, // <-- now unused, remove next major
  'Choose Download Format': `Escoge el Formato de Descarga`,
  'E-Book': `Libro Electrónico`,
  'Best for reading on a computer, phone, or tablet.': `La mejor opción para leer en computadora, teléfono o tablet.`,
  'Best for printing out your own copy.': `La mejor opción para imprimir tu propia copia`,
  'Choose eBook Type': `Selecciona el Tipo de Libro Electrónico`,
  'Best for text-to-speech apps like “Voice Dream.”': `La mejor opción para aplicaciones que convierten el texto a voz como “Voice Dream.”`,

  // old, remove next major
  'Best for most apps and platforms, including iOS.': `El mejor formato para la mayoría de aplicaciones y plataformas, incluyendo iOS.`,
  // replaced by below
  'Best for most apps and platforms, including Android, iOS, and newer Kindle devices.': `El mejor para la mayoría de aplicaciones y plataformas, incluyendo Android, iOS y los dispositivos más nuevos de Kindle.`,

  // old, remove next major
  'Best on Amazon devices, Kindle app, and Android.': `El mejor formato para los dispositivos Amazon, aplicaciones de Kindle y Android.`,
  // replaced by below
  'Best for older Android and Kindle devices.': `El mejor para dispositivos Android y Kindle antiguos.`,

  // Cart / Checkout
  'Your Order': `Tu Pedido`,
  'Continue Browsing': `Continuar Explorando`,
  'Handling Fee': `Gastos de Gestión`,
  Order: `Pedido`,
  Delivery: `Envío`,
  Payment: `Pago`,
  Confirmation: `Confirmación`,
  Item: `Artículo`,
  Price: `Precio`,
  Quantity: `Cantidad`,
  Remove: `Quitar`,
  Total: `Total`,
  City: `Ciudad`,
  'Qty.': `Cant.`,
  'Full name': `Nombre Completo`,
  'Apartment, suite, unit, etc.': `Apartamento, suite, casa, etc.`,
  'State / Province / Region': `Estado / Provincia / Región`,
  'Street address, P.O. Box, C/O': `Dirección`,
  'ZIP / Postal Code': `Código Postal`,
  'Select Country': `Seleccionar el País`,
  'Back to Order': `Regresar al Pedido`,
  'Name is required': `Se requiere tu nombre`,
  'Street address is required': `Se requiere tu dirección`,
  'City is required': `Se requiere tu ciudad`,
  'State / Province / Region is required': `Se requiere tu Estado / Provincia / Región`,
  'Email is required': `Se requiere tu correo electrónico `,
  'Valid email is required': `Se requiere tu correo electrónico `,
  'ZIP / Postal Code is required': `Se requiere tu código postal `,
  'Select a Country': `Selecciona tu país`,
  Subtotal: `Subtotal`,
  Shipping: `Envío`,
  Taxes: `Impuestos`,
  'Credit Card Fee Offset': `Tarifa de Tarjeta de Crédito`,
  'Grand Total': `Total General`,
  'Enter credit card details': `Introduce la información de tu tarjeta de Crédito`,
  'Back to Delivery': `Regresar a Envío`,
  Purchase: `Comprar`,
  'Try Again': `Intentar Nuevamente`,
  Close: `Cerrar`,
  'Confirmed!': `¡Confirmado!`,
  'Must be less than 30 characters': `No puede tener más de 30 caracteres`,

  // app
  Home: `Inicio`,
  Settings: `Configuraciones`,
  'High quality audio': `Audio de Alta Calidad`,
  'Downloaded audio': `Descargar Audio`,
  Delete: `Borrar`,
  Downloading: `Descargando`,
  'Download all': `Descargar todos`,
  'Delete all': `Borrar todos`,
  queued: `en espera`,
  'No internet': `No hay internet`,
  'Unable to download at this time': `No se puede descargar en este momento`,
  'No internet connection': `No hay conexión de internet`,
  'Get it on Google Play': `Disponible en Google Play`,
  'Download on the App Store': `Consíguelo en el App Store`,
  Read: `Leer`,
  Loading: `Cargando`,
  Preferences: `Preferencias`,
  'Continue reading': `Seguir leyendo`,
  'Continue listening': `Seguir escuchando`,
  'No Results': `No hay resultados`,
  'First %s Results': `Los Primeros %s Resultados`,
  '1 Result': `1 Resultado`,
  '%s Results': `%s Resultados`,

  // book publishing metadata
  'Original title': `Título original`,
  'Public domain in the USA': `Dominio público en los Estados Unidos de América`,
  'Originally published in %s': `Publicado originalmente en %s`,
  'friendslibrary.com': `bibliotecadelosamigos.org`,
  'info@friendslibrary.com': `info@bibliotecadelosamigos.org`,
  'Text revision %s': `Revisión de texto %s`,
  'Created and freely distributed by': `Creado y distribuido gratuitamente por`,
  'Find more free books from early Quakers at': `Encuentre más libros gratis de los primeros Cuáqueros en`,
  'Published by %s': `Publicado por %s`,
  'Contact the publishers at': `Puede contactarnos en`,

  // speech target
  footnote: `Nota a pie de página`,
  'returning to text': `Volviendo al texto`,
  Back: `Volver`,

  // geography
  Dublin: `Dublín`,
  Wales: `Gales`,
  Ireland: `Irlanda`,
  'Hemel Hempstead': `Hemel Hempstead`,
  England: `Inglaterra`,
  Scotland: `Escocia`,
  London: `Londres`,
  Philadelphia: `Filadelfia`,
  Pennsylvania: `Pensilvania`,
  Amsterdam: `Ámsterdam`,
  Netherlands: `Holanda`,
};

export default strings;
